export default class SpaceOperationsRequestsInquiriesForm {
    constructor() {
        this.formWrapper = null;
        this.subjectToggle = null;
        this.activeVinStatusToggles = null;
        this.activeVinStatusAdditionalSelectionOptions = null;
        this.visibilityToggles = null;
        this.submitButton = null;
    }

    init() {
        this.formWrapper = document.getElementById('tps-form-space-operations-requests-inquiries');

        if (this.formWrapper) {
            this.subjectToggle = document.querySelector('[data-sorif-subject-toggle]');
            this.activeVinStatusToggles = document.querySelectorAll('[data-sorif-active-vin-status-toggle]');
            this.activeVinStatusAdditionalSelectionOptions = {};
            this.visibilityToggles = document.querySelectorAll('[data-sorif-visibility]');
            this.submitButton = document.getElementById('form-space-operations-requests-inquiries-submit');

            this.initSubjectToggle();
            this.initActiveVinStatusToggleAndAdditionalSelection();
            this.initVisibilityToggles();
        }
    }

    initSubjectToggle() {
        const self = this;

        if (self.subjectToggle) {
            self.subjectToggle.addEventListener('change', function (e) {
                const targets = document.querySelectorAll('[data-sorif-subject]');

                targets.forEach(target => {
                    if (target.dataset.sorifSubject === self.subjectToggle.value) {
                        target.classList.remove('hide');
                    } else {
                        target.classList.add('hide');
                    }
                });
            });
        }
    }

    initActiveVinStatusToggleAndAdditionalSelection() {
        const self = this;

        if (self.activeVinStatusToggles) {
            self.activeVinStatusToggles.forEach(function (toggleDropdown) {
                // Store additional selection dropdown options for dynamic selection.
                let targetDropdown = document.querySelector(toggleDropdown.dataset.sorifActiveVinStatusToggle);
                let targetDropdownPlaceholderOption = targetDropdown.options[0];
                let targetDropdownOptgroups = targetDropdown.getElementsByTagName('optgroup');
                self.activeVinStatusAdditionalSelectionOptions[targetDropdown.id] = {};
                for (let i = 0; i < targetDropdownOptgroups.length; i++) {
                    self.activeVinStatusAdditionalSelectionOptions[targetDropdown.id][targetDropdownOptgroups[i].getAttribute('label')] = [targetDropdownPlaceholderOption, ...targetDropdownOptgroups[i].children];
                }
                targetDropdown.innerHTML = '';
                targetDropdown.options.add(targetDropdownPlaceholderOption);

                // initial (after forms submit)
                if (toggleDropdown.value !== '') {
                    self.activeVinStatusAdditionalSelectionOptions[targetDropdown.id][toggleDropdown.value].forEach(option => targetDropdown.options.add(option));
                }

                toggleDropdown.addEventListener('change', function (e) {
                    targetDropdown.innerHTML = '';
                    self.activeVinStatusAdditionalSelectionOptions[targetDropdown.id][toggleDropdown.value].forEach(option => targetDropdown.options.add(option));
                    targetDropdown.value = '';
                });
            });
        }
    }

    initVisibilityToggles() {
        const self = this;

        if (self.visibilityToggles) {
            self.visibilityToggles.forEach(function (toggle) {
                let data = toggle.dataset.sorifVisibility.split(':');
                let targetValue = data[0];
                let targetElement = document.querySelector(data[1]);

                // initial (aftrer form submit)
                if (toggle.value === targetValue) {
                    targetElement.classList.remove('hide');
                } else {
                    targetElement.classList.add('hide');
                }

                toggle.addEventListener('change', function (e) {
                    if (toggle.value === targetValue) {
                        targetElement.classList.remove('hide');
                    } else {
                        targetElement.classList.add('hide');
                    }
                })
            });
        }
    }

    setFormLoadingState(isLoading = true) {
        const self = this;

        if (isLoading) {
            document.body.style.pointerEvents = 'none';
            if (self.submitButton) {
                self.submitButton.classList.add('loading');
            }
        } else {
            document.body.style.pointerEvents = 'auto';
            if (self.submitButton) {
                self.submitButton.classList.remove('loading');
            }
        }
    }
}
